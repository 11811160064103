.type-of-courses-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.type-of-courses-container .list-group,
.type-of-courses-container .content-section {
  width: 50%;
}

.type-of-courses-container .main {
  background-color: #f7c756;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .type-of-courses-container {
    flex-direction: column;
    align-items: center;
  }

  .type-of-courses-container .list-group,
  .type-of-courses-container .content-section {
    width: 100%;
    max-width: 600px;
  }
}
