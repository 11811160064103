.circle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

.circle-wrapper::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  border: 5px solid transparent;
  border-top-color: #3590db;
  border-right-color: #f7c756;
  border-radius: 50%;
  animation: rotateCircle 4s infinite linear;
  z-index: 0;
}
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  background-color: #ffffff;
  padding: 50px;
  border-radius: 50%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.text-wrapper {
  margin-bottom: 40px;
}

.error-title {
  font-size: 6rem;
  color: #3590db;
  animation: bounceIn 1s ease-in-out;
}

.error-text {
  font-size: 1.8rem;
  color: #333;
  margin-top: 10px;
  animation: fadeIn 1.5s ease-in-out;
}

.subtext {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
  animation: fadeIn 1.5s ease-in-out;
}

.home-button {
  background-color: #3590db;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 50px;
  font-size: 1.2rem;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
}

.home-button:hover {
  transform: scale(1.1);
  background-color: #f7c756;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
    opacity: 1;
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 480px) {
  .circle-wrapper::before {
    width: 320px;
    height: 320px;
  }

  .not-found-container {
    padding: 20px;
  }

  .error-title {
    font-size: 3rem;
  }

  .error-text {
    font-size: 1.2rem;
  }

  .subtext {
    font-size: 0.7rem;
  }

  .home-button {
    font-size: 0.8rem;
    padding: 6px 16px;
  }
}
