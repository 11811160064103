.hidden {
  display: none;
}

.questionAnswerTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.questionAnswerTitle {
  font-family: var(--heading-font-family);
  font-weight: var(--text-font-weight);
  color: var(--color);
}

.questionAnswerIcon {
  flex-shrink: 0;
  margin-left: 12px;
}

.questionAnswerDescription {
  margin-top: 1px;
}

.questionAnswerTitle {
  font-size: var(--h6-font-size);
}

@media screen and (max-width: 576px) {
  .questionAnswerTitle {
    font-size: var(--small-h6-font-size);
  }
}

.questionAnswerContainer {
  display: flex;
  flex-direction: column;
  width: 900px;
  text-align: justify;
  cursor: default;
  border-bottom: 1px solid lightgrey;
  padding-top: 25px;
  padding-bottom: 1px; /* Adds space between the content and the border */
  margin-bottom: 25px; /* Adds space below the border */
}

@media (max-width: 1200px) {
  .questionAnswerContainer {
    width: 700px;
  }
}

@media (max-width: 992px) {
  .questionAnswerContainer {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .questionAnswerContainer {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .questionAnswerContainer {
    text-align: justify;
    align-items: center;
    width: 100%;
  }
}
