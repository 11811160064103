.service-card {
  position: relative;
  overflow: hidden;
}

.service-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s ease;
}
.card-heading {
  float: left;
  border-left: 1px solid black;
}
.card-heading-heading {
  margin-left: 10px;
}
.service-card:hover .service-img {
  transform: scale(1.1);
}

a {
  text-decoration: none;
  color: #3590db;
}

a:hover {
  color: #f7c756;
}

.service-card:hover .overlay {
  opacity: 1;
}

.service-number {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 4rem;
  color: black;
  width: 100px;
  background-color: #ffff;
  z-index: 2;
  text-align: center;
}
