.arrival-departure-container {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.outer-box {
  min-height: 450px;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.arrival-container {
  border: 2px solid #f7c756;
  border-radius: 10px;
  animation: borderPulseArrival 2s infinite;
}

.departure-container {
  border: 2px solid #3590db;
  border-radius: 10px;
  animation: borderPulseDeparture 2s infinite;
}

.inner-box {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
}

.arrival-inner-box {
  border: 2px solid #3590db;
  background-color: #f7c756;
  border-radius: 10px;
}

.departure-inner-box {
  border: 2px solid #f7c756;
  background-color: #3590db;
  border-radius: 10px;
}

@keyframes borderPulseArrival {
  0% {
    border-color: #f7c756;
  }
  50% {
    border-color: #f7c756;
    box-shadow: 0 0 15px #f7c756;
  }
  100% {
    border-color: #f7c756;
  }
}

@keyframes borderPulseDeparture {
  0% {
    border-color: #3590db;
  }
  50% {
    border-color: #3590db;
    box-shadow: 0 0 15px #3590db;
  }
  100% {
    border-color: #3590db;
  }
}
