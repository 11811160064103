.service-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.service-card:hover .overlay {
  opacity: 1;
}

.service-img {
  width: 100%;
  object-fit: cover;
}

.service-info {
  text-align: center;
}

@media (max-width: 768px) {
  .service-card {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .service-card {
    width: 100%;
    margin-bottom: 30px;
  }

  .service-info h6 {
    font-size: 18px;
  }
}
