.step-box {
  position: relative;
  padding: 20px;
  margin: 20px 10px 30px 15px;
  text-align: center;
  border-radius: 8px;
  border: 2px solid #3590db;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease,
    color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.step-box:hover {
  border: 2px solid #f7c756;
  color: #000;
  transform: translateY(-5px);
}

.step-content h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: inherit;
}

.step-content p {
  color: inherit;
}

.step-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.step-link:hover {
  color: #000;
}

.step-arrow {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  transition:
    border-color 0.3s ease,
    transform 0.3s ease;
}
