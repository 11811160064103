.number {
  font-size: 1.5rem;
}

.NumberBox {
  width: 50px;
  height: 50px;
  background: black;
}

.NumberBox:hover {
  background: #f7c756;
  color: black;
}

.NumberBox:hover .number {
  color: black;
}
.Icon {
  color: #f7c756;
}

.card {
  transition:
    transform 0.3s ease-in-out,
    box-shadow 0.3s ease-in-out;
  transform-origin: center bottom;
}

.card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.NumberBox {
  animation: pulse 2s infinite;
}
