.bg-color1 {
  background-color: #2d2d2d;
}

.footer-data {
  position: relative;
}

footer .subtitle1 {
  color: #bbbbbc;
  font-weight: 200;
}

.clc-tp-btn {
  position: absolute;
  top: 125px;
  right: -30px;
  background: #fff;
  padding: 10px;
}

.small-image {
  object-fit: cover;
  height: 80px;
  width: 100%;
  cursor: pointer;
}

.clc-tp-btn:hover {
  background-color: #f7c756;
}

.clc-tp-btn svg {
  width: 24px;
  height: 24px;
  fill: #f8f8f9;
}

.border-top {
  color: #bbbbbc;
}

.widget-box {
  margin-bottom: 20px;
}

.social-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.material-icons {
  font-size: 24px;
  vertical-align: middle;
  color: #bbbbbc;
}

.material-icons:hover {
  color: #e0e0e0;
}

.icons {
  color: #bbbbbc;
}

.icons:hover {
  color: #e0e0e0;
}

.scl-links a {
  color: #ffffff;
  font-size: 24px;
  margin-right: 20px;
}

.scl-links a:hover {
  color: #e0e0e0;
}

.cont-list li {
  font-size: 1rem;
  line-height: 1.875rem;
  margin-bottom: 10px;
}

.cont-list li span.material-icons {
  vertical-align: middle;
  font-size: 20px;
  margin-right: 8px;
}

.widget-gallery img {
  object-fit: cover;
}

.widget-gallery .row {
  margin-right: -8px;
}

.widget-title {
  font-family: "Roboto";
  line-height: 1.875rem;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 15px;
  color: #ffffff;
}

.lineheight {
  line-height: 1.875rem;
}

footer a {
  text-decoration: none;
  color: #bbbbbc;
}

footer a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .small-image {
    height: 50px;
  }

  .clc-tp-btn {
    top: 0;
    right: 0;
  }
}

.scroll-to-top {
  background-color: white;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .scroll-to-top {
    margin-right: 10px;
  }
}

.scroll-to-top :hover {
  background-color: #f7c756;
}

.arrow {
  height: 50px;
  width: 50px;
  padding: 10px;
  color: #1e1e20;
}
