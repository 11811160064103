.header-container {
  padding: 0px;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.dropdown > .dropdown-menu {
  border-radius: 0;
  border: 0;
  min-width: 14.375rem;
  padding-right: 2.1875rem;
  white-space: nowrap;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 20;
  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 35px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
  }
}

.dropdown-item {
  color: #000;
  font-weight: normal;
}

.dropdown-item:hover {
  color: #fff;
  font-weight: bold;
}

.lni-grid-alt {
  font-size: 1.5rem;
}

.lni-cross-circle {
  font-size: 2rem;
}

#welcome {
  background-color: #eaeae9;
  color: #3e4455;
}

.nav-item {
  margin-right: 10px;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addIcon {
  margin-left: 10px;
  color: #f4c36d;
}

.offcanvas {
  background-color: #fff;
}

.offcanvas .offcanvas-header {
  background-color: #fff;
}

.offcanvas .offcanvas-body {
  background-color: black;
}
.navbar-toggler-icon-white {
  filter: invert(1);
}

.navbar-toggler-icon-black {
  filter: invert(0);
}

.item {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 30px;
}
.nav-link {
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
}

.nav-link:hover .body1 {
  font-weight: bold;
}
.dropdown-menu {
  border-radius: 0;
  padding: 0;
  display: none;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-item {
  color: #000;
  font-weight: normal;
  padding: 10px 20px;
  position: relative;
}

.dropdown-item a {
  color: inherit;
  text-decoration: none;
  display: block;
  transition:
    color 0.3s,
    font-weight 0.3s;
}

.dropdown-item a:hover {
  color: black;
  font-weight: bold;
}

.dropdown-item a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  color: black;
}

.dropdown-item a:hover::before {
  visibility: visible;
  width: 100%;
  color: "white";
}

.offcanvas-open .dropdown-menu {
  background-color: white;
  border: none;
}

.item-link {
  text-decoration: none;
  color: #3e4455;
  position: relative;
}

.item-link:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.item-link::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #f4c36d;
  transition: width 0.3s;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.item-link:hover::after {
  width: 100%;
}

.closeIcon {
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
  line-height: 1.5rem;
  border-radius: 50%;
  background-color: black;
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .nav-link,
  .item-link {
    color: #000 !important;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
  }

  .navbar-nav {
    margin-right: 0;
    justify-content: center; /* Center the menu items */
  }
}
.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.Header.scrolled {
  background-color: white;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.1);
}
.navbar {
  background-color: transparent;
}

.Header.scrolled .navbar {
  background-color: white;
}
