.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
}

.modal-background {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.modal-content {
  max-width: 100%; /* Allow the modal to adapt to the available width */
  max-height: 100%; /* Allow the modal to adapt to the available height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* Prevent any content overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.modal-image {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 40px;
  height: 40px;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1001;
}

@media (max-width: 480px) {
  .modal-close {
    display: none;
  }
  .modal-arrow {
    font-size: 10px;
  }
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
