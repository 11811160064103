.custom-container-three-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.page-row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title-image {
  width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.main-image {
  width: 100%;
  height: 421px;
  object-fit: cover;
}

.paragraph-start,
.expand {
  display: flex;
  flex-direction: column;
  width: 900px;
  text-align: justify;
}

@media (max-width: 1200px) {
  .title-image,
  .paragraph-start,
  .expand {
    width: 700px;
  }
}

@media (max-width: 992px) {
  .title-image,
  .paragraph-start,
  .expand {
    width: 600px;
  }
  .main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .title-image,
  .paragraph-start,
  .expand {
    width: 100%;
  }

  .custom-container-three-pages {
    padding: 15px;
  }
  .main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

@media (max-width: 576px) {
  .page-row,
  .title-image,
  .paragraph-start,
  .expand {
    text-align: justify;
    align-items: center;
    width: 100%;
  }
  .main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
