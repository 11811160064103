.custom-container {
  padding-top: 32px;
  margin-top: 80px;
}

.moreVideo {
  text-decoration: none;
}

.moreVideo:hover {
  color: black;
  text-decoration: underline;
}
.roadmap-video:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 1.25rem;
  z-index: -1;
  background-color: #f4c36d;
  top: 1.25rem;
}

.roadmap-video {
  flex: 0 0 29.375rem;
  max-width: 29.375rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
}

.roadmap-info-wrap {
  padding: 0 2.5rem;
  position: relative;
}

.roadmap-info-wrap:before {
  content: "";
  position: absolute;
  height: 15.625rem;
  width: 1px;
  background-color: black;
  top: 50%;
  left: 7.1875rem;
  margin-top: -7.8125rem;
}

.roadmap-info-wrap > span {
  font-family: Arapey;
  color: #f6f0f0;
  font-size: 3.125rem;
  min-width: 5rem;
  text-align: center;
  line-height: 2.8125rem;
  left: 4.8125rem;
  top: 50%;
  margin-top: -1.40625rem;
}

.number:hover {
  background-color: #f4c36d;
  color: #1e1e20;
}
.vedio-text {
  text-align: justify;
}
.intro-one {
  width: 100%;
  height: 319px;
}
@media (max-width: 992px) {
  .roadmap-info-wrap {
    display: none;
  }
}

@media (max-width: 576px) {
  .roadmap-video {
    padding-left: 0px;
    height: 300px;
    justify-content: center;
    padding: 20px;
  }
  .roadmap-video:before {
    width: 100%;
  }
  .intro-one {
    width: 100%;
    height: 250px;
    margin-top: 15px;
  }
}
