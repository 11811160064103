.center-cont {
  height: auto;
  width: 77%;
}

.section {
  margin-bottom: 40px;
  scroll-margin-top: 100px;
}

.section-title {
  color: Black;
  font-size: 30px;
  margin-bottom: 15px;
}

.img-center {
  width: 300px;
  height: 200px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.image-caption-gallery {
  text-align: center;
}
.carousel-indicators button {
  box-sizing: border-box;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #600 !important;
  border: 1px solid white;
  margin: 5px;
  opacity: 1 !important;
  transition: opacity 0.6s ease;
}

.carousel-indicators button.active {
  background-color: #600 !important;
  opacity: 1 !important;
}
.carousel-indicators .small-indicator {
  display: none;
}
.carousel-indicators {
  bottom: -60px !important;
  left: 34%;
  transform: translateX(-50%);
}

.gallery-grid {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin: 0;
  padding: 0;
}

.img-gallery {
  width: 300px;
  height: 270px;
  object-fit: cover;
  scroll-snap-align: start;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: transparent;
  background-size: 200%, 200%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 1 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.carousel-control-prev {
  left: -90px;
}

.carousel-control-next {
  right: -85px;
}

.modal-gall {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
}

.cross-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

.selected-gall {
  max-width: 100%;
  margin-top: 50px;
  max-height: 90%; /* Make image large but within view */
}

.image-caption {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  padding: 10px;
}
.prev-btn,
.next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
}

.prev-btn {
  left: 100px;
}

.next-btn {
  right: 100px;
}

.img-center-two {
  width: 400px;
  height: 250px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 30px;
}

.img-caption-two {
  margin-left: 30px;
}

.scroll-cont {
  position: sticky;
  top: 173px;
  left: 1120px;
  background-color: #fffdd0;
  height: 355px;
  width: 20%;
  padding: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 5px;
  margin-left: 30px;
}

.all-txts {
  text-align: justify;
}

.scroll-cont-in {
  flex-grow: 1;
  overflow-y: auto;
  pointer-events: auto;
}

.all-linked-txt {
  display: flex;
  flex-direction: column;
}

.center-txt {
  cursor: pointer;
  font-family: var(--text-font-family);
  font-size: var(--body1-font-size);
  font-weight: 350;
  line-height: 1.7;
  margin: 0;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.center-txt:hover {
  text-decoration: underline;
  color: #3590db;
}

.section-cont {
  position: relative;
  z-index: 0;
}

.trans-text {
  font-family: var(--text-font-family);
  font-size: var(--body1-font-size);
  font-weight: 300;
  line-height: 2.5rem;
  margin: 0;
}
.carousel-indicators .small-indicator {
  display: none;
}

.desktop-gallery {
  display: block;
}
.mobile-gallery {
  display: none;
}
.scroll-desk {
  display: block;
}
.scroll-mob {
  display: none;
}
@media (max-width: 768px) {
  .mobile-gallery {
    display: block;
  }

  .desktop-gallery {
    display: none;
  }
  .scroll-desk {
    display: none;
  }
  .scroll-mob {
    display: block;
  }
  .img-mobile-gallery {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  /* Mobile View Carousel Indicators */
  .carousel-indicators-mobile button {
    position: relative;
    bottom: -30px !important;
    box-sizing: border-box;
    height: 12.5px !important;
    border-radius: 50%;
    background-color: #600 !important;
    border: 1px solid white;
    margin: 5px;
    opacity: 0.5;
    transition: opacity 0.6s ease;
  }

  .carousel-indicators-mobile button.active {
    background-color: #600 !important;
    opacity: 1 !important;
  }

  .carousel-indicators-mobile {
    position: absolute;
    bottom: 10px !important;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 1 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }
  .cross-btn {
    top: 140px;
    right: 10px;
  }

  .selected-gall {
    max-width: 95%;
    margin-top: 50%;
  }
  .modal-gall {
    padding: 10px;
  }
  .cross-btn {
    position: absolute;
    top: 150px;
    right: 10px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }

  .selected-gall {
    max-width: 100%;
    margin-top: 50px;
    max-height: 90%; /* Make image large but within view */
  }

  .image-caption {
    font-size: 1rem;
  }

  .prev-btn {
    left: 20px;
  }

  .next-btn {
    right: 20px;
  }
}

@media (max-width: 768px) {
  .center-cont {
    height: auto;
    width: 77%;
    margin: 0 auto;
  }

  .the-centre-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .scroll-cont.fixed {
    position: sticky;
    width: 100%;
    height: 355px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: #fffdd0;
    top: 0px; /* Adjust this value to move it below the header */
  }

  .scroll-cont-in {
    flex-grow: 1;
    overflow-y: auto;
  }

  .img-center {
    width: 100%;
    max-width: 100%;
  }
  .img-center-two {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
  .gallery-grid {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
  }

  .img-gallery {
    width: 100%;
    max-width: none;
    flex: 0 0 auto;
  }
  .carousel-control-prev {
    left: 10px;
  }

  .carousel-control-next {
    right: 10px;
  }
  .map-container {
    width: 100%;
    height: 400px;
  }
  .gallery-grid {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .gallery-grid {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .carousel-indicators {
    display: none;
  }
}
@media (max-width: 480px) {
  .center-cont {
    width: 100%;
  }

  .scroll-cont {
    width: 100%;
    padding: 20px;
  }

  .center-txt {
    font-size: 16px;
    line-height: 1.5;
  }

  .img-center {
    width: 100%;
    height: auto;
  }
}
