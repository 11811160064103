@import url("https://fonts.googleapis.com/css2?family=Arapey:ital,wght@0,400;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --heading-font-family: "Arapey", serif;
  --text-font-family: "Roboto", sans-serif;
  --heading-font-weight: 700;
  --text-font-weight: 400;
  --color: #1e1e20;
  --h1-font-size: 96px;
  --h2-font-size: 72px;
  --h3-font-size: 56px;
  --h4-font-size: 42px;
  --h5-font-size: 32px;
  --h6-font-size: 24px;
  --small-h1-font-size: 64px;
  --small-h2-font-size: 48px;
  --small-h3-font-size: 40px;
  --small-h4-font-size: 32px;
  --small-h5-font-size: 24px;
  --small-h6-font-size: 20px;
  --heading-font-size: 22px;
  --body1-font-size: 1.125rem;
  --body2-font-size: 0.875rem;
  --subtitle1-font-size: 1rem;
  --subtitle2-font-size: 0.875rem;
  --label-font-size: 1rem;
}

.heading {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight1);
  font-size: var(--heading-font-size);
  color: var(--color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font-family);
  font-weight: var(--text-font-weight);
  color: var(--color);
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}
.body1 {
  font-family: var(--text-font-family);
  font-size: var(--body1-font-size);
  font-weight: 300;
  color: var(--color);
  line-height: 1.875rem;
}

.body2 {
  font-family: var(--text-font-family);
  font-size: var(--body2-font-size);
  font-weight: 300;
  color: var(--color);
  line-height: 1.875rem;
}

.subtitle1 {
  font-family: var(--text-font-family);
  font-size: var(--subtitle1-font-size);
  font-weight: var(--text-font-weight);
  color: var(--color);
}

.subtitle2 {
  font-family: var(--text-font-family);
  font-size: var(--subtitle2-font-size);
  font-weight: var(--text-font-weight);
  color: var(--color);
}

label {
  font-family: var(--text-font-family);
  font-size: var(--label-font-size);
  font-weight: 300;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: var(--small-h1-font-size);
  }
  h2 {
    font-size: var(--small-h2-font-size);
  }
  h3 {
    font-size: var(--small-h3-font-size);
  }
  h4 {
    font-size: var(--small-h4-font-size);
  }
  h5 {
    font-size: var(--small-h5-font-size);
  }
  h6 {
    font-size: var(--small-h6-font-size);
  }
  .body1 {
    font-size: 1rem;
  }
  .body2 {
    font-size: 0.75rem;
  }
  .subtitle1 {
    font-size: 0.875rem;
  }
  .subtitle2 {
    font-size: 0.75rem;
  }
}

.btn-warning {
  background-color: #f7c756;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  height: 59px;
  width: 168px;
  color: #000;
  text-transform: uppercase;
  transition:
    background-color 0.5s ease-in-out,
    transform 0.5s ease-in-out,
    color 0.5s ease-in-out;
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 0px;
}

.card:hover .btn-warning {
  transform: scale(0.9);
}

.btn-warning:hover {
  background-color: #3590db;
  color: #fff;
}

.btn-warning::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 1px solid transparent;
  transition: border-color 0.5s ease-in-out;
  z-index: 1;
}

.btn-warning:hover::before {
  border-color: #3590db;
}

.btn-warning.border-visible::before {
  border-color: #3590db;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}
