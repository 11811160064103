.service-vedio-cont {
  width: 100%;
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  justify-content: space-between; /* Side by side layout for larger screens */
  flex-wrap: wrap; /* Ensure elements wrap on smaller screens */
}

.service-vedio-cont1,
.service-vedio-cont2 {
  border-radius: 10px;
  padding: 20px;
  width: 48%; /* Take 48% of the width on larger screens to fit side by side */
  height: auto; /* Allow height to adjust */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.service-vedio-cont1 {
  border: 2px solid #3590db;
  animation: borderPulseDeparture 2s infinite;
}

.service-vedio-cont2 {
  border: 2px solid #f7c756;
  animation: borderPulseArrival 2s infinite;
  margin-left: 20px; /* Add space between the two containers */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .service-vedio-cont {
    width: 100%;
    flex-direction: column; /* Stack the two videos vertically */
    align-items: center; /* Center the video containers on small screens */
    margin: 0px;
  }

  .service-vedio-cont1,
  .service-vedio-cont2 {
    width: 100%; /* Full width on small screens */
    height: 300px;
    margin-left: 0; /* Remove left margin */
    margin-bottom: 20px; /* Add space between stacked videos */
  }
  .sevice-ved1 {
    width: 100%;
    height: 250px;
  }
}
