.card-title {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.card-link:hover .card {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.card {
  height: 100%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn-center {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  text-align: center;
  background-color: #3590db;
  color: white;
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.btn-center:hover {
  background-color: #f7c756;
  color: black;
}

.btn-center::before,
.btn-center::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  z-index: 1;
  transition: transform 0.4s ease;
}

.btn-center::before {
  border: 4px solid #f7c756;
  transform: scaleX(0);
  transform-origin: left;
}

.btn-center::after {
  border: 4px solid #3590db;
  transform: scaleX(0);
  transform-origin: right;
}

.btn-center:hover::before {
  transform: scaleX(1);
}

.btn-center:hover::after {
  transform: scaleX(1);
}

.btn-center span {
  position: relative;
  z-index: 2;
}
