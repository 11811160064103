.media-box {
  height: 1000px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.media-box video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.45;
  z-index: 0;
}

.vedios-image-content {
  min-height: 500px;
  display: flex;
}
.image-vedios {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.vedio-image {
  height: 100%;
  width: 49%;
  margin-left: 260px;
  padding: 1vw;
  overflow: hidden;
  text-align: justify;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.vedio2 {
  height: 100%;
  width: 45%;
  padding: 1vw;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.vedios-image-self {
  height: 300px;
  width: 400px;
  margin-top: 50px;
}
.special-first {
  position: relative;
  left: 100px;
}
.special-third {
  position: relative;
  left: 150px;
}
.image-self {
  height: 300px;
  width: 400px;
  margin-top: 50px;
  position: relative;
  right: 100px;
}
.aboutVip {
  margin-top: 50px;
  height: 80%;
  width: 35%;
  justify-content: right;
  background-image: url("./assets/aboutus1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.aboutCou {
  margin-top: 50px;
  height: 70%;
  width: 35%;
  gap: 10px;
  margin-left: 260px;
  background-image: url("./assets/aboutus2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.aboutDha {
  margin-top: 50px;
  height: 80%;
  width: 35%;
  background-image: url("./assets/aboutus3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.special-second {
  height: 600px;
  text-align: justify;
}
.image-space {
  margin-top: 0px;
}

.custom-outline-btn {
  border-color: #3590db;
  padding: 10px 20px;
  width: 180px;
  height: 50px;
  text-align: center;
  background-color: white;
}

.custom-outline-btn:hover {
  background-color: #3590db;
  color: white;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out;
}

.zoom-in {
  transform: scale(1.1);
  opacity: 1;
}

.zoom-out {
  transform: scale(1);
  opacity: 0.5;
}

.image-container::-webkit-scrollbar {
  display: none;
}

.image-container img {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.dhamma-space {
  margin-left: 50px;
}

.text-overlay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.learn-btn {
  background-color: #f7c756;
  color: #000;
  padding: 10px 30px;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  box-shadow: none;
  transition: all 0.4s ease;
}

.learn-btn::before,
.learn-btn::after {
  content: "";
  position: absolute;
  background-color: #000;
  transition: all 0.4s ease;
}

.learn-btn::before {
  height: 2px;
  width: calc(100% + 15px);
  bottom: -10px;
  left: -5px;
}

.learn-btn::after {
  width: 2px;
  height: 50%;
  right: -10px;
  bottom: -10px;
}

.learn-btn:hover::before {
  width: 100%;
  bottom: 0;
  left: 0;
}

.learn-btn:hover::after {
  height: 100%;
  right: 0;
  bottom: 0;
}

.learn-btn:hover {
  box-shadow: inset 0 0 0 2px #000;
}

.dhammaheading {
  letter-spacing: 1.5rem;
  font-size: 60px;
  line-height: 1.2;
  text-align: center;
}
.dhammatext {
  color: #f7c756;
  margin-bottom: 20px;
  letter-spacing: 1px;
  white-space: nowrap;
}
@media (max-width: 1200px) {
  .dhammaheading {
    font-size: 3rem;
  }
  .dhammatext {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .vedio-image,
  .vedio2 {
    width: 100%;
    float: none;
    padding: 5px;
  }
  .vedios-image-content {
    flex-direction: column; /* Stack the video and image container vertically */
    height: auto; /* Adjust height for smaller screens */
  }
  .aboutVip {
    margin-top: 0px;
    height: 300px;
    width: 100%;
    justify-content: center;
  }
  .vedio-image {
    height: 100%;
    width: 100%;
    padding-left: 0px;
  }
  .vedios-image-self {
    height: auto;
    width: 100%;
    margin: 30px;
    left: 0px;
  }
  .aboutDha {
    margin-top: 0px;
    height: auto;
    width: 100%;
  }
  .aboutCou {
    margin-top: 0px;
    height: 300px;
    width: 100%;
    gap: 0px;
    padding-left: 0px;
  }
  .image-self {
    height: auto;
    width: 100%;
    margin: 30px;
    right: 0px;
  }
  .special-second {
    flex-direction: column-reverse;
  }
  .image-space {
    margin-top: 50px;
  }
  .image {
    height: 480px;
  }

  .new-carousel {
    height: 25vh;
  }
  .dhammaheading {
    font-size: 2.5rem;
    letter-spacing: 1rem;
  }
  .dhammatext {
    font-size: 0.875rem;
    white-space: normal;
  }
}

@media (max-width: 576px) {
  .dhammaheading {
    font-size: 2rem;
  }
  .dhammatext {
    font-size: 0.75rem;
  }
}
@media (max-width: 768px) {
  .media-box {
    height: 580px;
  }
}
