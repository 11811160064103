.donation-details {
  background-color: #ffffff;
  border: 2px solid #f7c756;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  max-width: 460px;
  margin: auto;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.donation-details:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
