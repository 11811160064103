.video-container1 {
  position: relative;
  display: flex;
  cursor: pointer;
  margin: auto;
  border: 1px solid #f4c36d;
}

.play-button-first {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 10px;
  width: 80px;
  height: 80px;
  color: #333;
  transition:
    transform 0.3s ease,
    padding 0.3s ease;
  border: 1.4px solid #f4c36d;
}

.play-button-second {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #f4c36d;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.play-icon {
  border-left: 20px solid black;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.play-button-first:hover {
  padding: 15px;
  width: 100px;
  height: 100px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: white;
  font-weight: 500;
}

.goenkaji {
  width: 100%;
  height: 580px;
  object-fit: cover;
}

.thumbnail {
  width: 100%;
  height: 700px;
}
.goenka-btn {
  bottom: 92px;
}
.hover-thumbnail {
  width: 80%;
  height: 80%;
}
@media (max-width: 768px) {
  .goenkaji {
    width: 100%;
    height: 400px;
  }
  .goenka-btn {
    bottom: 0px;
  }
}

@media (max-width: 768px) {
  .thumbnail {
    width: 100%;
    height: auto;
  }
  .close-button {
    position: absolute;
    top: 150px;
  }
  .hover-thumbnail {
    width: 100%;
    height: 40%;
  }
}
