.whattobring-container {
  border-radius: 15px;
  padding: 2rem;
  margin-top: 8rem;
  box-shadow: 0 2px 10px #3590db;
  animation: fadeIn 1s ease-in-out;
}

.whattobring-heading {
  color: #3590db;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  animation: slideInFromLeft 1.2s ease-in-out;
  border-bottom: 3px solid #f7c756;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.whattobring-section-title {
  color: #f7c756;
  font-weight: 600;
  text-transform: uppercase;
  animation: slideInFromRight 1s ease-in-out;
  border-left: 5px solid #3590db;
  padding-left: 10px;
}

.whattobring-list {
  padding-left: 1.5rem;
  list-style-type: none;
}

.whattobring-list-item {
  position: relative;
  color: #3590db;
  animation: fadeInItem 0.5s ease-in-out;
  padding-left: 25px;
}

.whattobring-list-item::before {
  content: "✓";
  color: #f7c756;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  animation: fadeInCheckmark 0.5s ease-in-out forwards;
}

.whattobring-list-item strong {
  color: #f7c756;
  font-weight: bold;
  padding: 0 5px;
  border-radius: 5px;
}

.whattobring-note {
  color: #3590db;
  font-weight: 600;
}

.whattobring-note strong {
  color: #f7c756;
  font-weight: bold;
  text-decoration: underline;
}
.whattobring-list-item:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
  background-color: rgba(53, 144, 219, 0.1);
  border-radius: 5px;
  padding-left: 30px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInItem {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInCheckmark {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .custom-container.whattobring-container {
    padding: 1.5rem;
  }
  .whattobring-container {
    padding: 1.5rem;
    width: 90%;
  }
}
@media (max-width: 576px) {
  .whattobring-container {
    padding: 1rem;
    width: 90%;
  }
}
